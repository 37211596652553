import React from 'react';
import { Footer, Possibility, Features, WhatP, Header, Contact} from './containers';
import { CTA, Brand, Navbar } from './components';
import './App.css';

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    <WhatP />
    <Features />
    <Possibility />
    <CTA />
    <Contact />
      <Footer />
  </div>
);

export default App;
