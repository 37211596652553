import React, { useState } from 'react';
import ai from '../../assets/ai.png';
import './header.css';
import Typewriter from "typewriter-effect";
import { db } from "../../firebase";

const Header = () => {
  const [email, setEmail] = useState("");

const handleSubmit = (e) => {
  e.preventDefault();
  
  // Check if the email already exists in the database
  db.collection("Get_Start_users")
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        // The email already exists in the database
        alert("Email already registered.");
        setEmail("");
      } else {
        // The email doesn't exist in the database, add it
        db.collection("Get_Start_users")
          .add({
            email: email,
          })
          .then(() => {
            alert("Your message has been submitted");
          })
          .catch((error) => {
            alert(error.message);
          });
        setEmail("");
      }
    })
    .catch((error) => {
      alert(error.message);
    });
};

  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">
                     <Typewriter
              options={{
                strings: "Let's Build something amazing with Parathesis.AI",
                autoStart: true,
                loop: false,
              }}
            />
        </h1>
        <p class="animated-text">Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>

        <form onSubmit={handleSubmit}>
          <div className="gpt3__header-content__input">
            <input type="email" placeholder="Your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button type="submit" disabled={!email}>Get Started</button>
          </div>
        </form>

        <div className="gpt3__header-content__people">

        </div>
      </div>

      <div className="gpt3__header-image">
        <img src={ai} alt="logo" />
      </div>
    </div>
  );
};
export default Header;

//Let&apos;s Build Something amazing with Parathesis.AI