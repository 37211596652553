import React from 'react';
import './brand.css';

const Brand = () => (
  <div className="gpt3__brand section__padding">
   
  </div>
);

export default Brand;
