import React, { useState, useEffect, useRef } from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import Typewriter from "typewriter-effect";

const Possibility = () => {
  const [inViewport, setInViewport] = useState(false);
  const possibilityContentRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      if (possibilityContentRef.current) {
        const { top, bottom } = possibilityContentRef.current.getBoundingClientRect();
        if (top < window.innerHeight && bottom > 0) {
          setInViewport(true);
        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image">
        <img src={possibilityImage} alt="possibility" />
      </div>
      <div className="gpt3__possibility-content" ref={possibilityContentRef}>
        <h1 className="gradient__text">
          {inViewport &&
            <Typewriter
              options={{
                strings: "The possibilities are \nbeyond your imagination",
                autoStart: true,
                loop: false,
              }}
            />
          }
        </h1>
        <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>
      </div>
    </div>
  );
};

export default Possibility;
