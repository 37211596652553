import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD0-8gNl4uB7aR1XI8T7kAbbcKVeulLxG8",
  authDomain: "getstart-bb3c8.firebaseapp.com",
  projectId: "getstart-bb3c8",
  storageBucket: "getstart-bb3c8.appspot.com",
  messagingSenderId: "603393531944",
  appId: "1:603393531944:web:37b54297f313d5ef08551d"
});

var db = firebaseApp.firestore();

export default firebase;
export { db };
