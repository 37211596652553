import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';


const Footer = () => (
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Do you want to step into the future before others?"</h1>
      </div>
      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={gpt3Logo} alt="gpt3_logo" />
          <p>Parathesis, <br /> All Rights Reserved</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Links</h4>
          <p><a href="https://github.com/Parathesis">Github</a></p>
          <p><a href="#contact">Contact</a></p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Company</h4>
          <p><a href="https://terms.parathesis.vercel.app">Terms & Conditions</a></p>
        <p><a href="#contact">Contact</a></p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Get in touch</h4>
          <p>Parathesis AI</p>
          <p>parathesis.ai@gmail.com</p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>@2023 Parathesis.AI. All rights reserved.</p>
      </div>
    </div>
  );

export default Footer;
