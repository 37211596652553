import React, { useState } from "react";
import "./Form.css";
import { db } from "../../firebase";
import badWords from "bad-words";

const Contact = ({ handleCloseModal }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
    const filter = new badWords();
    const content = `${name} ${email} ${message}`;
    if (filter.isProfane(content)) {
      alert("Your message contains inappropriate content");
      return;
    }
   
    if (!name || !email || !message) {
    alert("Please enter all inputs");
    return;
    }
     if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Please enter a valid email address");
      return;
     }
    setLoader(true);

    db.collection("Contact_Us")
      .add({
        name: name,
        email: email,
        message: message,
      })
      .then(() => {
        setLoader(false);
        alert("Your message has been submitted");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (

  <form className="form contact-form" onSubmit={handleSubmit} id="contact">
      <h1>Contact Us</h1>

      <label>Name</label>
      <input
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <label>Email</label>
      <input
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <label>Message</label>
      <textarea
        placeholder="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>

      <button
        type="submit"
        style={{ background: loader ? "#ccc"  : " #FF4820" }}
        disabled={!name || !email || !message}
      >
        Submit
      </button>
    </form>
  );
};

export default Contact;
